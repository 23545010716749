body {
  background-color: #106e5e;
}
body .App .App-header {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
body .App .App-header .App-logo {
  max-width: 520px;
}
body .App .App-content {
  padding-top: 45px;
}
body .App .App-content .App-widget {
  border: 0.0625rem solid #3B9588;
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  background: rgb(20, 22, 32);
  max-width: 810px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 20px;
  text-align: center;
}
body .App .App-content .App-widget .App-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .App .App-content .App-widget .App-input-wrapper .App-input {
  color: #000000;
  background-color: #FFFFFF;
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
  font-size: 0.9375rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.28);
  border: 1px solid #A3BAC6;
  border-radius: 3px;
  margin: 0.5rem 0;
  padding: 0.5rem 0.625rem 0.5625rem;
}
body .App .App-content .App-widget .App-input-wrapper .App-domain {
  color: #FFFFFF;
  display: inline-block;
  margin-left: 10px;
  font-size: 1.4861rem;
}
body .App .App-content .App-widget .error-message {
  text-align: center;
  border-radius: 2px;
  margin: 10px auto;
  max-width: 370px !important;
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  padding: 7px;
  color: #cc0033;
  font-size: 13px;
  font-weight: bold;
  text-shadow: 1px 1px rgba(250, 250, 250, 0.3);
}
body .App .App-content .App-widget .btn-submit {
  background-color: #1CA693;
  color: #FFFFFF;
  font-size: 1.1146rem;
  font-weight: 400;
  height: 2.625rem;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  margin-top: 35px;
  transition: background-color 0.3s ease;
  max-width: 26.5rem;
  width: 100%;
}
body .App .App-content .App-widget .btn-submit:hover {
  background-color: #25675E;
  color: #FFFFFF;
}
body .App .App-footer {
  padding-top: 40px;
}
body .App .App-footer p {
  text-align: center;
  color: #ffffff;
}
body .App .App-footer p a {
  color: #1AE5BB;
}
body .App .App-footer p a:hover {
  text-decoration: underline;
}

